var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c("rt-messages"),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("h2", { staticClass: "display-2" }, [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "subheading font-weight-light v-xsmall-hide" },
              [_vm._v("Select an Agency for schedules and status information:")]
            ),
          ]),
          _vm._v(" "),
          _c("rt-agency-list", { attrs: { agencies: _vm.agencies } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("rt-info"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }