var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400", scrollable: "" },
      on: {
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.onKeyDownEnter.apply(null, arguments)
          },
          _vm.onKeyDown,
        ],
      },
      model: {
        value: _vm.properties.visible,
        callback: function ($$v) {
          _vm.$set(_vm.properties, "visible", $$v)
        },
        expression: "properties.visible",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "card" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [
              _c("v-icon", [_vm._v("place")]),
              _vm._v("  Select " + _vm._s(_vm.label()) + "\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-icon", { on: { click: _vm.close } }, [_vm._v("close")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "card-body" },
            [
              _c(
                "v-list",
                _vm._l(
                  _vm.filterStops || _vm.properties.stops,
                  function (stop) {
                    return _c(
                      "v-list-tile",
                      {
                        key: stop.id,
                        staticClass: "list-item",
                        on: {
                          click: function ($event) {
                            return _vm.stopSelected(stop)
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-tile-content",
                          [
                            _c(
                              "v-list-tile-title",
                              {
                                class: {
                                  disabled:
                                    _vm.properties.type === "station" &&
                                    stop.statusId === "-1",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(stop.name) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticStyle: { "background-color": "#eee" } },
            [
              _c("v-text-field", {
                ref: "filter",
                attrs: { label: "Filter Stops", "hide-details": "" },
                model: {
                  value: _vm.filterValue,
                  callback: function ($$v) {
                    _vm.filterValue = $$v
                  },
                  expression: "filterValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }