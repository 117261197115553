var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "trip-wrapper",
      class: {
        "highlight-trip-wrapper": _vm.highlight,
        "share-trip-wrapper": _vm.share_selected,
      },
      on: { click: _vm.selectTrip },
    },
    [
      _vm.peakResult
        ? _c(
            "div",
            { staticClass: "peak" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-icon",
                              _vm._g({ staticClass: "peak-icon" }, on),
                              [_vm._v("monetization_on")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3592363062
                  ),
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "One or more trains on this trip is a peak train and a higher fare may be required"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDepartsInTimes
        ? _c("div", {
            class: _vm.condensed
              ? "trip-departs-time-condensed"
              : "trip-departs-time",
            domProps: { innerHTML: _vm._s(_vm.departs) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.trip.segments, function (segment, index) {
        return _c(
          "div",
          { key: "trip-segment-" + index, staticClass: "trip-segment-wrapper" },
          [
            _vm.showHeadsigns
              ? _c(
                  "div",
                  {
                    class: _vm.condensed
                      ? "trip-segment-headsign-condensed"
                      : "trip-segment-headsign",
                  },
                  [
                    _c(
                      "span",
                      {
                        style: {
                          "background-color": "#" + segment.trip.route.color,
                          color: "#" + segment.trip.route.textColor,
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              color: "#" + segment.trip.route.textColor,
                            },
                          },
                          [_vm._v("train")]
                        ),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(segment.trip.headsign) + " "),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "trip-segment-times" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatTime(segment.enter.departureTime)) +
                    " "
                ),
                _c("v-icon", { attrs: { color: "#111" } }, [
                  _vm._v("arrow_forward"),
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.formatTime(segment.exit.arrivalTime)) +
                    "\n        "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "trip-segment-status" },
              [
                _c("v-fade-transition", [
                  _c("div", {
                    key: "status-" + _vm.status[index],
                    domProps: { innerHTML: _vm._s(_vm.status[index]) },
                  }),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "trip-segment-traveltime" }, [
              _vm.showTravelTimes
                ? _c(
                    "span",
                    [
                      _vm._v("\n                 "),
                      _c("v-icon", { attrs: { color: "#111" } }, [
                        _vm._v("access_time"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatTravelTime(segment.travelTime)) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "trip-segment-track" },
              [
                _c("v-fade-transition", [
                  _c("div", {
                    key: "track-" + _vm.track[index],
                    domProps: { innerHTML: _vm._s(_vm.track[index]) },
                  }),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-expand-transition",
              [
                _vm.tripDetailsVisible
                  ? _c("rt-trip-details", {
                      staticClass: "trip-segment-details",
                      attrs: {
                        trip: segment.trip,
                        enter: segment.enter,
                        exit: segment.exit,
                        position: _vm.getPosition(segment),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.trip.transfers[index]
              ? _c("div", { staticClass: "trip-segment-transfer" }, [
                  _c(
                    "div",
                    { staticClass: "trip-segment-transfer-icon" },
                    [
                      _c("v-icon", { attrs: { color: "#111" } }, [
                        _vm._v("airline_stops"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "trip-segment-transfer-info" }, [
                    _c("strong", [
                      _vm._v(
                        " Transfer @ " +
                          _vm._s(_vm.trip.transfers[index].stop.name)
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.condensed && _vm.showTravelTimes
                      ? _c("span", [
                          _vm._v(
                            " (" +
                              _vm._s(
                                _vm.formatTravelTime(
                                  _vm.trip.transfers[index].layoverTime,
                                  true
                                )
                              ) +
                              ")"
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    !_vm.condensed && _vm.showTravelTimes
                      ? _c(
                          "span",
                          [
                            _c("v-icon", { attrs: { color: "#111" } }, [
                              _vm._v("timelapse"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatTravelTime(
                                    _vm.trip.transfers[index].layoverTime
                                  )
                                )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.showTravelTimes && _vm.trip.segments.length > 1
        ? _c(
            "div",
            { staticClass: "trip-total-traveltime" },
            [
              !_vm.condensed ? _c("div", { staticClass: "spacing" }) : _vm._e(),
              _vm._v(" "),
              !_vm.condensed
                ? _c("span", [
                    _c("strong", [_vm._v("Total Travel Time")]),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("v-icon", { attrs: { color: "#111" } }, [_vm._v("update")]),
              _vm._v(
                " " +
                  _vm._s(_vm.formatTravelTime(_vm.trip.travelTime)) +
                  "\n    "
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }