var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.messages, function (message) {
        return [
          _c(
            "v-alert",
            { key: message.id, attrs: { value: true, color: "warning" } },
            [
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.dismissMessage(message.id)
                    },
                  },
                },
                [_vm._v("cancel")]
              ),
              _vm._v(" "),
              _c("h2", [_vm._v(_vm._s(message.title))]),
              _vm._v(" "),
              _c("span", { domProps: { innerHTML: _vm._s(message.body) } }),
              _vm._v(" "),
              message.linkTitle && message.linkUrl
                ? _c("v-btn", { attrs: { href: message.linkUrl, block: "" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(message.linkTitle) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }