var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "container" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "secondary-bg" },
            [
              _c("v-icon", { attrs: { large: "", left: "" } }, [
                _vm._v("person_add"),
              ]),
              _vm._v(" "),
              _c("h2", [_vm._v("Create Account")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "subheading" }, [
                _vm._v("\n                Create a "),
                _c("strong", [_vm._v("Right Track Account")]),
                _vm._v(" to sync your favorites across devices.\n            "),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      box: "",
                      "prepend-icon": "email",
                      label: "Email Address",
                      hint: _vm.emailHint,
                      type: "email",
                      rules: [_vm.validate.email],
                      "validate-on-blur": "",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.register.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: {
                      box: "",
                      "prepend-icon": "person",
                      label: "Username",
                      hint: _vm.usernameHint,
                      rules: [_vm.validate.username],
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.register.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.username,
                      callback: function ($$v) {
                        _vm.username = $$v
                      },
                      expression: "username",
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: {
                      box: "",
                      "prepend-icon": "lock",
                      label: "Password",
                      hint: _vm.passwordHint,
                      type: "password",
                      rules: [_vm.validate.password],
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.register.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", { staticClass: "button-container" }, [
                    _c(
                      "div",
                      { staticClass: "button-register" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", disabled: !_vm.valid },
                            on: { click: _vm.register },
                          },
                          [
                            _c("v-icon", [_vm._v("person_add")]),
                            _vm._v(" Create Account\n                        "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "button-login" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", outline: "" },
                            on: { click: _vm.login },
                          },
                          [
                            _c("v-icon", [_vm._v("person_outline")]),
                            _vm._v(" Log In\n                        "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }