var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _vm._l(_vm.results, function (trip, index) {
        return [
          _c(
            "v-card",
            {
              key: "trip-" + index,
              class: _vm.display_condensed
                ? "trip-result-card-condensed"
                : "trip-result-card",
              attrs: { id: "trip-result-card-" + index },
            },
            [
              _c("rt-trip-result-item", {
                attrs: {
                  trip: trip,
                  index: index,
                  highlight: index === _vm.resultsHighlightIndex,
                  condensed: _vm.display_condensed,
                  showHeadsigns: _vm.display_showHeadsigns,
                  showTravelTimes: _vm.display_showTravelTimes,
                  showDepartsInTimes: _vm.display_showDepartsInTimes,
                  statusFeeds: _vm.statusFeeds,
                  share_started: _vm.share_started,
                  share_selected: _vm.share_selectedResults.includes(index),
                },
                on: {
                  addShareSelectedResult: _vm.onAddShareSelectedResult,
                  removeShareSelectedResult: _vm.onRemoveShareSelectedResult,
                },
              }),
            ],
            1
          ),
        ]
      }),
      _vm._v(" "),
      _c("v-fade-transition", [
        _vm.results && _vm.results.length === 0
          ? _c(
              "div",
              { staticClass: "trip-results-none" },
              [
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("v-icon", { attrs: { color: "#111", size: "75" } }, [
                  _vm._v("error_outline"),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("h2", [_vm._v("No Results Found")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "subheading trip-results-none-subheading" },
                  [
                    _vm._v("\n                No Trips were found between "),
                    _c("strong", [_vm._v(_vm._s(_vm.origin.name))]),
                    _vm._v(" and "),
                    _c("strong", [_vm._v(_vm._s(_vm.destination.name))]),
                    _vm._v(
                      " \n                departing around " +
                        _vm._s(_vm.departure.getTimeReadable()) +
                        " on " +
                        _vm._s(_vm.departure.getDateReadable()) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("ul", { staticClass: "trip-results-none-list" }, [
                  _c("li", [
                    _c("a", { on: { click: _vm.checkUpdates } }, [
                      _vm._v(
                        "Make sure you have the latest schedule database installed"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("a", { on: { click: _vm.checkSettings } }, [
                      _vm._v(
                        "If your trip requires a transfer, make sure transfers are enabled"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("a", { on: { click: _vm.reportError } }, [
                      _vm._v(
                        "Report a possible error in the schedule database"
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.results
        ? _c(
            "div",
            { staticClass: "trip-results-loading" },
            [
              _c("v-progress-circular", {
                attrs: { size: 100, color: "primary", indeterminate: "" },
              }),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("h3", [_vm._v("Calculating Routes...")]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _c("br"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }