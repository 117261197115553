var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasEvents
      ? _c(
          "div",
          { staticClass: "event-card-list" },
          _vm._l(_vm.transitEvents, function (event, index) {
            return _c(
              "v-card",
              { key: index, staticClass: "event-card" },
              [
                _c("v-card-title", { staticClass: "secondary-bg" }, [
                  _c("span", { staticClass: "headline" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(event.status) +
                        "\n                "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("v-card-text", {
                  staticClass: "event-details",
                  domProps: { innerHTML: _vm._s(event.details) },
                }),
              ],
              1
            )
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }