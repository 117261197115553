var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "container" }, [
    _vm.display === "login"
      ? _c("div", [
          _c("p", [
            _vm._v("You must be logged in before verifying an email address."),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.display === "request"
      ? _c(
          "div",
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "secondary-bg" },
                  [
                    _c("v-icon", { attrs: { large: "", left: "" } }, [
                      _vm._v("verified_user"),
                    ]),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Verify Email")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  [
                    _c("p", { staticClass: "subheading" }, [
                      _vm._v(
                        "\n                    This will verify the email address associated with your account by sending a verification link \n                    to your registered email address.  After following the link your email address will be verified.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "v-form",
                      { ref: "form" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            disabled: "",
                            box: "",
                            "prepend-icon": "person",
                            label: "Email Address",
                            type: "email",
                          },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.request.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "button-request-container" }, [
                          _c(
                            "div",
                            { staticClass: "button-request" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.requesting,
                                    color: "primary",
                                  },
                                  on: { click: _vm.request },
                                },
                                [
                                  _c("v-icon", [_vm._v("verified_user")]),
                                  _vm._v(
                                    "  Verify Email\n                            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "button-request-login" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.requesting,
                                    color: "primary",
                                    outline: "",
                                  },
                                  on: {
                                    click: _vm.cancel,
                                    "update:disabled": function ($event) {
                                      _vm.requesting = $event
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("cancel")]),
                                  _vm._v(
                                    "  Cancel\n                            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display === "sent"
      ? _c(
          "div",
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "secondary-bg" },
                  [
                    _c("v-icon", { attrs: { large: "", left: "" } }, [
                      _vm._v("verified_user"),
                    ]),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Link Sent")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-card-text", [
                  _c("p", { staticClass: "subheading" }, [
                    _vm._v(
                      "\n                    A link to verify your email has been sent to the email address registered to the account.\n                    Look for an email from "
                    ),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.request_resp.confirmation.from)),
                    ]),
                    _vm._v(" with a subject of\n                    "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.request_resp.confirmation.subject)),
                    ]),
                    _vm._v(
                      ".  You may need to check your mail's \n                    Junk/Spam folder.  The link will expire on " +
                        _vm._s(_vm.request_resp.token.expires) +
                        ".\n                "
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display === "verify"
      ? _c(
          "div",
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "secondary-bg" },
                  [
                    _c("v-icon", { attrs: { large: "", left: "" } }, [
                      _vm._v("verified_user"),
                    ]),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Verify Email")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("v-progress-circular", {
                      attrs: { color: "primary", size: 50, indeterminate: "" },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { staticClass: "subheading" }, [
                      _vm._v(
                        "\n                    Verifying your email address...\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display === "success"
      ? _c(
          "div",
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "secondary-bg" },
                  [
                    _c("v-icon", { attrs: { large: "", left: "" } }, [
                      _vm._v("verified_user"),
                    ]),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Verify Email")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("v-icon", { attrs: { large: "" } }, [
                      _vm._v("verified_user"),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { staticClass: "subheading" }, [
                      _vm._v(
                        "\n                    Email Verified!\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.verifying,
                          color: "primary",
                          outline: "",
                        },
                        on: {
                          click: _vm.cancel,
                          "update:disabled": function ($event) {
                            _vm.verifying = $event
                          },
                        },
                      },
                      [
                        _c("v-icon", [_vm._v("cancel")]),
                        _vm._v("  Close\n                "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display === "error"
      ? _c(
          "div",
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "secondary-bg" },
                  [
                    _c("v-icon", { attrs: { large: "", left: "" } }, [
                      _vm._v("verified_user"),
                    ]),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Verify Email")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("v-icon", { attrs: { large: "" } }, [_vm._v("error")]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { staticClass: "subheading" }, [
                      _c("strong", [_vm._v("Email Address Not Verified")]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.errorMessage) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.verifying,
                          color: "primary",
                          outline: "",
                        },
                        on: {
                          click: _vm.cancel,
                          "update:disabled": function ($event) {
                            _vm.verifying = $event
                          },
                        },
                      },
                      [
                        _c("v-icon", [_vm._v("cancel")]),
                        _vm._v("  Close\n                "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }