var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showStatus
        ? _c("div", { staticClass: "status-wrapper" }, [
            _c("div", { staticClass: "status-label" }, [
              _c(
                "span",
                {
                  class: [
                    { "status-label-text-good": !_vm.hasEvents },
                    { "status-label-text-bad": _vm.hasEvents },
                    "status-label-text",
                  ],
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.status) + "\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "status-icon" },
              [
                !_vm.hasEvents
                  ? _c("v-icon", { staticClass: "status-icon-check" }, [
                      _vm._v("check_circle"),
                    ])
                  : _c("span", { staticClass: "status-icon-badge badge-bad" }, [
                      _vm._v(_vm._s(_vm.transitDivision.eventCount)),
                    ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.divisions, function (division) {
        return _c(
          "div",
          {
            key: division.code,
            staticClass: "division-wrapper",
            on: {
              click: function ($event) {
                return _vm.selectDivision(division)
              },
            },
          },
          [
            division.icon
              ? _c("div", { staticClass: "division-icon" }, [
                  _c("img", { attrs: { src: division.icon } }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "division-name",
                style: {
                  "background-color": division.backgroundColor,
                  color: division.textColor,
                },
              },
              [_vm._v("\n            " + _vm._s(division.name) + "\n        ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "division-spacer" }),
            _vm._v(" "),
            division.status && division.eventCount > 0
              ? _c("div", { staticClass: "division-status v-small-hide" }, [
                  _vm._v(
                    "\n            " + _vm._s(division.status) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "division-badge" },
              [
                division.eventCount === 0
                  ? [
                      _c(
                        "span",
                        { staticClass: "badge-good" },
                        [_c("v-icon", [_vm._v("check_circle")])],
                        1
                      ),
                    ]
                  : [
                      _c("span", { staticClass: "badge-bad" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(division.eventCount) +
                            "\n                "
                        ),
                      ]),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "division-more" },
              [_c("v-icon", [_vm._v("chevron_right")])],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }