var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-item" },
    [
      _c("h3", [_vm._v("Trip Results Display:")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Display Density:")]),
              _c("br"),
              _vm._v("\n                Display the results in a spaced out "),
              _c("strong", [_vm._v("comfortable")]),
              _vm._v(" view or \n                a more compact "),
              _c("strong", [_vm._v("condensed")]),
              _vm._v(" view\n            "),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { sm1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm3: "" } },
            [
              _c("v-select", {
                attrs: { filled: "", items: _vm.displayCondensed_options },
                model: {
                  value: _vm.displayCondensed,
                  callback: function ($$v) {
                    _vm.displayCondensed = $$v
                  },
                  expression: "displayCondensed",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs10: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Departs In Times")]),
              _c("br"),
              _vm._v(
                "Display the 'Departs in x minutes' for upcoming departures"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { staticClass: "hidden-xs-only", attrs: { xs1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs1: "" } },
            [
              _c("v-switch", {
                staticClass: "mt-1",
                attrs: { color: "primary" },
                model: {
                  value: _vm.showDepartsInTimes,
                  callback: function ($$v) {
                    _vm.showDepartsInTimes = $$v
                  },
                  expression: "showDepartsInTimes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs10: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Trip Headsigns")]),
              _c("br"),
              _vm._v("Display the final destination of trip segments"),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { staticClass: "hidden-xs-only", attrs: { xs1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs1: "" } },
            [
              _c("v-switch", {
                staticClass: "mt-1",
                attrs: { color: "primary" },
                model: {
                  value: _vm.showHeadsigns,
                  callback: function ($$v) {
                    _vm.showHeadsigns = $$v
                  },
                  expression: "showHeadsigns",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs10: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Travel Times")]),
              _c("br"),
              _vm._v(
                "Display the calculated travel times of trips and their segments"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { staticClass: "hidden-xs-only", attrs: { xs1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs1: "" } },
            [
              _c("v-switch", {
                staticClass: "mt-1",
                attrs: { color: "primary" },
                model: {
                  value: _vm.showTravelTimes,
                  callback: function ($$v) {
                    _vm.showTravelTimes = $$v
                  },
                  expression: "showTravelTimes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("h3", [_vm._v("Trip Searches:")]),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Pre-Departure Hours:")]),
              _c("br"),
              _vm._v("\n                The number of hours "),
              _c("strong", [_vm._v("before")]),
              _vm._v(
                " the search time to include in the results\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { sm1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm3: "" } },
            [
              _c("v-select", {
                attrs: { filled: "", items: _vm.preDepartureHours_options },
                model: {
                  value: _vm.preDepartureHours,
                  callback: function ($$v) {
                    _vm.preDepartureHours = $$v
                  },
                  expression: "preDepartureHours",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Post-Departure Hours:")]),
              _c("br"),
              _vm._v("\n                The number of hours "),
              _c("strong", [_vm._v("after")]),
              _vm._v(
                " the search time to include in the results\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { sm1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm3: "" } },
            [
              _c("v-select", {
                attrs: { filled: "", items: _vm.postDepartureHours_options },
                model: {
                  value: _vm.postDepartureHours,
                  callback: function ($$v) {
                    _vm.postDepartureHours = $$v
                  },
                  expression: "postDepartureHours",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(7),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("h3", [_vm._v("Transfer Settings:")]),
      _vm._v(" "),
      _vm._m(8),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs10: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Allow Transfers")]),
              _c("br"),
              _vm._v(
                "Include trip results that require switching between trains"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { staticClass: "hidden-xs-only", attrs: { xs1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs1: "" } },
            [
              _c("v-switch", {
                staticClass: "mt-1",
                attrs: { color: "primary" },
                model: {
                  value: _vm.allowTransfers,
                  callback: function ($$v) {
                    _vm.allowTransfers = $$v
                  },
                  expression: "allowTransfers",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(9),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Max Transfers:")]),
              _c("br"),
              _vm._v(
                "\n                The maximum number of transfers allowed on a single trip\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { sm1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm3: "" } },
            [
              _c("v-select", {
                attrs: {
                  filled: "",
                  items: _vm.maxTransfers_options,
                  disabled: !_vm.allowTransfers,
                },
                model: {
                  value: _vm.maxTransfers,
                  callback: function ($$v) {
                    _vm.maxTransfers = $$v
                  },
                  expression: "maxTransfers",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(10),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs10: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Allow Change in Direction:")]),
              _c("br"),
              _vm._v(
                "\n                Allow a transfer between trains that run in opposite directions (this may require an additional fare)\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { staticClass: "hidden-xs-only", attrs: { xs1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs1: "" } },
            [
              _c("v-switch", {
                staticClass: "mt-1",
                attrs: { color: "primary", disabled: !_vm.allowTransfers },
                model: {
                  value: _vm.allowChangeInDirection,
                  callback: function ($$v) {
                    _vm.allowChangeInDirection = $$v
                  },
                  expression: "allowChangeInDirection",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(11),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Min Layover Mins:")]),
              _c("br"),
              _vm._v("\n                The "),
              _c("strong", [_vm._v("minimum")]),
              _vm._v(
                " amount of time between arrival and departure of trains at a transfer\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { sm1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm3: "" } },
            [
              _c("v-select", {
                attrs: {
                  filled: "",
                  items: _vm.minLayoverMins_options,
                  disabled: !_vm.allowTransfers,
                },
                model: {
                  value: _vm.minLayoverMins,
                  callback: function ($$v) {
                    _vm.minLayoverMins = $$v
                  },
                  expression: "minLayoverMins",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(12),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Max Layover Mins:")]),
              _c("br"),
              _vm._v("\n                The "),
              _c("strong", [_vm._v("maximum")]),
              _vm._v(
                " amount of time between arrival and departure of trains at a transfer\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { sm1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm3: "" } },
            [
              _c("v-select", {
                attrs: {
                  filled: "",
                  items: _vm.maxLayoverMins_options,
                  disabled: !_vm.allowTransfers,
                },
                model: {
                  value: _vm.maxLayoverMins,
                  callback: function ($$v) {
                    _vm.maxLayoverMins = $$v
                  },
                  expression: "maxLayoverMins",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }