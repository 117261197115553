var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-item" },
    [
      _c("h3", [_vm._v("Schedule Updates")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs10: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Check for Updates Automatically")]),
              _c("br"),
              _vm._v(
                "\n                When the app/website is running, automatically check for a schedule database update \n                and show a notification when one is available\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { staticClass: "hidden-xs-only", attrs: { xs1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs1: "" } },
            [
              _c("v-switch", {
                staticClass: "mt-1",
                attrs: { color: "primary" },
                model: {
                  value: _vm.autoCheck,
                  callback: function ($$v) {
                    _vm.autoCheck = $$v
                  },
                  expression: "autoCheck",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "v-layout",
        { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
            _c("p", [
              _c("strong", [_vm._v("Update Check Frequency")]),
              _c("br"),
              _vm._v(
                "\n                The time interval between update checks\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { sm1: "" } }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm3: "" } },
            [
              _c("v-select", {
                attrs: {
                  filled: "",
                  items: _vm.autoCheckFrequency_options,
                  disabled: !_vm.autoCheck,
                  "item-text": "label",
                  "item-value": "value",
                },
                model: {
                  value: _vm.autoCheckFrequency,
                  callback: function ($$v) {
                    _vm.autoCheckFrequency = $$v
                  },
                  expression: "autoCheckFrequency",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.agencyId
        ? _c(
            "div",
            [
              _c(
                "v-layout",
                { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                    _c("p", [
                      _c("strong", [_vm._v("Last Checked:")]),
                      _c("br"),
                      _vm._v(_vm._s(_vm.lastChecked)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-flex", { attrs: { sm1: "" } }),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { staticClass: "mt-2", attrs: { xs12: "", sm3: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", block: "" },
                          on: { click: _vm.check },
                        },
                        [
                          _vm._v(
                            "\n                    Check Now\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Currently Installed:")]),
                _c("br"),
                _vm.version ? _c("span", [_vm._v("Version ")]) : _vm._e(),
                _vm._v(_vm._s(_vm.version)),
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h3", [_vm._v("Reset Database")]),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c(
                "v-layout",
                { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                    _c("p", [
                      _vm._v("Clear the currently installed database and "),
                      _c("strong", [_vm._v("download the latest schedules")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-flex", { attrs: { sm1: "" } }),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { staticClass: "mt-2", attrs: { xs12: "", sm3: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", block: "" },
                          on: { click: _vm.reset },
                        },
                        [
                          _vm._v(
                            "\n                    Reset\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _c(
                "v-layout",
                { staticClass: "mt-1", attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                    _c("p", [
                      _vm._v("Clear the currently installed database and "),
                      _c("strong", [
                        _vm._v("download a previous set of schedules"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-flex", { attrs: { sm1: "" } }),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { staticClass: "mt-2", attrs: { xs12: "", sm3: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.restoring,
                            block: "",
                          },
                          on: { click: _vm.restore },
                        },
                        [
                          _vm._v(
                            "\n                    Restore\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br"), _c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden-sm-and-up" }, [_c("br"), _c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }