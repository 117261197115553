var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "empty-state" },
      [
        _c("v-icon", { staticClass: "empty-state-icon" }, [
          _vm._v("contact_support"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "display-2" }, [_vm._v("Page Not Found")]),
        _vm._v(" "),
        _c("p", { staticClass: "subheading" }, [
          _vm._v("Uh Oh! The page you requested could not be found."),
        ]),
        _vm._v(" "),
        _c("br"),
        _c("br"),
        _vm._v(" "),
        _c(
          "v-btn",
          { attrs: { color: "primary" }, on: { click: _vm.agencyList } },
          [_vm._v("\n            List of Supported Agencies\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }