var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c("rt-messages"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyState,
              expression: "showEmptyState",
            },
          ],
          staticClass: "empty-state",
        },
        [
          _c("v-icon", { staticClass: "empty-state-icon" }, [_vm._v("stars")]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "subheading" },
            [
              _vm._v("\n            Use the "),
              _c("v-icon", [_vm._v("edit")]),
              _vm._v(" button below to add a favorite.\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "subheading" },
            [
              _vm._v("\n            A "),
              _c("strong", [_vm._v("Trip")]),
              _vm._v(" "),
              _c("v-icon", [_vm._v("train")]),
              _vm._v(
                " displays departure and arrival times between \n            two stops. A "
              ),
              _c("strong", [_vm._v("Station")]),
              _vm._v(" "),
              _c("v-icon", [_vm._v("access_time")]),
              _vm._v(
                " displays real-time status \n            information for upcoming departures from a single stop. A "
              ),
              _c("strong", [_vm._v("Transit Line")]),
              _vm._v(" "),
              _c("v-icon", [_vm._v("warning")]),
              _vm._v(
                " displays the current status information for the selected line, branch, \n            or roadway.\n        "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showLogin,
                  expression: "showLogin",
                },
              ],
              staticClass: "favorites-login",
            },
            [
              _c(
                "p",
                { staticClass: "subheading" },
                [
                  _vm._v("\n                Use your "),
                  _c("strong", [_vm._v("Right Track Account")]),
                  _vm._v(
                    " to sync your favorites across devices or \n                use the "
                  ),
                  _c("v-icon", [_vm._v("edit")]),
                  _vm._v(
                    " button now to add your favorites directly to this device.\n            "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "div",
                  { staticClass: "button-login" },
                  [
                    _c(
                      "v-btn",
                      { attrs: { color: "primary" }, on: { click: _vm.login } },
                      [
                        _c("v-icon", [_vm._v("person_outline")]),
                        _vm._v(" Log In\n                    "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "button-register" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", outline: "" },
                        on: { click: _vm.register },
                      },
                      [
                        _c("v-icon", [_vm._v("person_add")]),
                        _vm._v(" Create Account\n                    "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFavorites,
              expression: "showFavorites",
            },
          ],
        },
        [
          _c(
            "v-card",
            { staticClass: "favorites-card" },
            [
              _c(
                "v-card-title",
                { staticClass: "headline secondary-bg" },
                [
                  _c("v-icon", [_vm._v("star")]),
                  _vm._v("  Favorites\n            "),
                ],
                1
              ),
              _vm._v(" "),
              _c("rt-favorites-list", {
                attrs: {
                  favorites: _vm.favorites,
                  removeMode: _vm.removeMode,
                  removeSelected: _vm.removeSelected,
                  reorderMode: _vm.reorderMode,
                },
                on: {
                  updateRemoveSelected: _vm.onUpdateRemoveSelected,
                  updateFavorites: _vm.onUpdateFavorites,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.databaseInfo
            ? _c("div", { staticClass: "db-info-container" }, [
                _c("p", { staticClass: "font-weight-light" }, [
                  _c("strong", [_vm._v("Database Version:")]),
                  _vm._v(" " + _vm._s(_vm.databaseInfo.version)),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Published:")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.databaseInfo.gtfs_publish_date_formatted) +
                      "\n            "
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-speed-dial",
        {
          staticClass: "favorites-fab",
          attrs: {
            fixed: "",
            direction: "top",
            transition: "slide-y-reverse-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-bg",
                      attrs: { fab: "" },
                      model: {
                        value: _vm.fab,
                        callback: function ($$v) {
                          _vm.fab = $$v
                        },
                        expression: "fab",
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("edit")]),
                      _vm._v(" "),
                      _c("v-icon", [_vm._v("close")]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v
            },
            expression: "fab",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "primary-fg",
              attrs: { small: "", fab: "" },
              on: { click: _vm.selectTrip },
            },
            [_c("v-icon", [_vm._v("train")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "primary-fg",
              attrs: { small: "", fab: "" },
              on: { click: _vm.selectStation },
            },
            [_c("v-icon", [_vm._v("access_time")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "primary-fg",
              attrs: { small: "", fab: "" },
              on: { click: _vm.selectTransit },
            },
            [_c("v-icon", [_vm._v("warning")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "primary-fg",
              attrs: { small: "", fab: "" },
              on: { click: _vm.reorderFavorites },
            },
            [_c("v-icon", [_vm._v("shuffle")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "primary-fg",
              attrs: { small: "", fab: "" },
              on: { click: _vm.removeFavorites },
            },
            [_c("v-icon", [_vm._v("delete")])],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("rt-stop-selection-dialog", {
        attrs: { properties: _vm.stopSelectionDialogProps },
        on: { stopSelected: _vm.onStopSelected },
      }),
      _vm._v(" "),
      _c("rt-transit-selection-dialog", {
        attrs: { properties: _vm.transitSelectionDialogProps },
        on: { transitSelected: _vm.onTransitSelected },
      }),
      _vm._v(" "),
      _c("rt-train-number-selection-dialog", {
        attrs: { properties: _vm.trainNumberSelectionDialogProps },
        on: { trainNumberSelected: _vm.onTrainNumberSelected },
      }),
      _vm._v(" "),
      _c("rt-train-number-details-dialog", {
        attrs: { properties: _vm.trainNumberDetailsDialogProps },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }