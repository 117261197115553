var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400", persistent: "" },
      model: {
        value: _vm.properties.active,
        callback: function ($$v) {
          _vm.$set(_vm.properties, "active", $$v)
        },
        expression: "properties.active",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline secondary-bg" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.properties.title) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("v-card-text", {
            domProps: { innerHTML: _vm._s(_vm.properties.content) },
          }),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", flat: "" },
                  on: { click: _vm.properties.onCancel },
                },
                [_vm._v(_vm._s(_vm.properties.cancel))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", dark: "" },
                  on: { click: _vm.properties.onConfirm },
                },
                [_vm._v(_vm._s(_vm.properties.confirm))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }