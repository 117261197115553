var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.agencies, function (agency) {
      return _c(
        "div",
        {
          key: agency.id,
          staticClass: "agency-wrapper",
          on: {
            click: function ($event) {
              return _vm.selectAgency(agency.id)
            },
          },
        },
        [
          _c("div", { staticClass: "agency-icon" }, [
            _c("img", { attrs: { src: agency.icon } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "agency-name" }, [
            _vm._v("\n            " + _vm._s(agency.name) + "\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "agency-more" },
            [_c("v-icon", [_vm._v("chevron_right")])],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }