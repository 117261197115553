var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "right-track-info" }, [
    _c("h3", [_c("strong", [_vm._v(_vm._s(_vm.config.title))])]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "p",
      [
        _c("v-icon", [_vm._v("code")]),
        _vm._v("  "),
        _c("a", { attrs: { href: _vm.config.maintainer.repository } }, [
          _vm._v("Source Available on GitHub"),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("v-icon", [_vm._v("email")]),
        _vm._v("  "),
        _c("a", { attrs: { href: "mailto:" + _vm.config.maintainer.email } }, [
          _vm._v(_vm._s(_vm.config.maintainer.email)),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "font-weight-light" }, [
      _vm._v(
        "Copyright © " +
          _vm._s(_vm.year) +
          " " +
          _vm._s(_vm.config.maintainer.name)
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Part of the "),
      _c("strong", [_vm._v("Right Track Library")]),
      _vm._v(", an open source commuter rail project."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }