var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [_c("v-icon", [_vm._v("info")]), _vm._v("  About\n        ")],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [
            _c("p", { staticClass: "subheading" }, [
              _c("strong", [_vm._v(_vm._s(_vm.config.title))]),
              _vm._v(
                " is an open-source Progressive Web App providing commuter-rail users with scheduled train times combined with real-time status information.  The \n                software powering the website is available under the terms of its \n                "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      _vm.config.maintainer.repository + "/blob/master/LICENSE",
                  },
                },
                [_vm._v("License")]
              ),
              _vm._v(".\n            "),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h2", [_vm._v(_vm._s(_vm.config.title))]),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("strong", [_vm._v("Installed Version: ")]),
                _vm._v(_vm._s(_vm.site.version) + " ("),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        _vm.config.maintainer.repository + "/commits/master",
                    },
                  },
                  [_vm._v(_vm._s(_vm.site.hash))]
                ),
                _vm._v(")\n                "),
                _c("br"),
                _vm._v(" "),
                _c("strong", [_vm._v("Latest Version: ")]),
                _vm._v(_vm._s(_vm.site.availableVersion) + " ("),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        _vm.config.maintainer.repository + "/commits/master",
                    },
                  },
                  [_vm._v(_vm._s(_vm.site.availableHash))]
                ),
                _vm._v(")\n                "),
                _c("br"),
                _vm._v(" "),
                _c("strong", [_vm._v("Source Code:")]),
                _vm._v(" "),
                _c("a", { attrs: { href: _vm.config.maintainer.repository } }, [
                  _vm._v(_vm._s(_vm.config.maintainer.repository)),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("strong", [_vm._v("Client: ")]),
                _vm._v(_vm._s(_vm.client) + "\n                "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "reload-button",
                    attrs: {
                      color: "primary",
                      title:
                        "Reload the app to ensure you have the latest version",
                      small: "",
                      outline: "",
                    },
                    on: { click: _vm.reloadApp },
                  },
                  [
                    _c("v-icon", [_vm._v("cloud_download")]),
                    _vm._v("  Update App \n                "),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h2", [_vm._v(_vm._s(_vm.server.name))]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Host: ")]),
              _c("a", { attrs: { href: "//" + _vm.server.host } }, [
                _vm._v(_vm._s(_vm.server.host)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("strong", [_vm._v("Version: ")]),
              _vm._v(_vm._s(_vm.server.version) + "\n                "),
              _c("br"),
              _vm._v(" "),
              _c("strong", [_vm._v("Source Code: ")]),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.server.maintainer.source } }, [
                _vm._v(_vm._s(_vm.server.maintainer.source)),
              ]),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h2", [_vm._v("Supported Agencies")]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.agencies, function (agency) {
                return _c("li", [
                  _c("strong", [_vm._v(_vm._s(agency.name))]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("strong", [_vm._v("Installed Version:")]),
                      _vm._v(" " + _vm._s(agency.database.installed)),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("strong", [_vm._v("Latest Version:")]),
                      _vm._v(" " + _vm._s(agency.database.version)),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("strong", [_vm._v("Maintainer:")]),
                      _vm._v(
                        " \n                            " +
                          _vm._s(agency.maintainer.name) +
                          " \n                            <"
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "mailto:" + agency.maintainer.email },
                        },
                        [_vm._v(_vm._s(agency.maintainer.email))]
                      ),
                      _vm._v("> \n                        "),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("strong", [_vm._v("Source Code:")]),
                      _vm._v(" "),
                      _c("a", { attrs: { href: agency.maintainer.source } }, [
                        _vm._v(_vm._s(agency.maintainer.source)),
                      ]),
                    ]),
                  ]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h2", [_vm._v("Supported Transit Agencies")]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.transitAgencies, function (agency) {
                return _c("li", [
                  _c("strong", [_vm._v(_vm._s(agency.name))]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("strong", [_vm._v("Maintainer:")]),
                      _vm._v(
                        " \n                            " +
                          _vm._s(agency.maintainer.name) +
                          " \n                            <"
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "mailto:" + agency.maintainer.email },
                        },
                        [_vm._v(_vm._s(agency.maintainer.email))]
                      ),
                      _vm._v("> \n                        "),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("strong", [_vm._v("Source Code:")]),
                      _vm._v(" "),
                      _c("a", { attrs: { href: agency.maintainer.source } }, [
                        _vm._v(_vm._s(agency.maintainer.source)),
                      ]),
                    ]),
                  ]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("br"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }