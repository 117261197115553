var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab-item" }, [
    _c("h3", [_vm._v("User Account")]),
    _vm._v(" "),
    !_vm.isLoggedIn
      ? _c("div", [
          _vm._m(0),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "button-container" }, [
            _c(
              "div",
              { staticClass: "button-login" },
              [
                _c(
                  "v-btn",
                  { attrs: { color: "primary" }, on: { click: _vm.login } },
                  [
                    _c("v-icon", [_vm._v("person_outline")]),
                    _vm._v(" Log In\n                "),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "button-register" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", outline: "" },
                    on: { click: _vm.register },
                  },
                  [
                    _c("v-icon", [_vm._v("person_add")]),
                    _vm._v(" Create Account\n                "),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isLoggedIn
      ? _c(
          "div",
          [
            _c(
              "v-container",
              { staticClass: "px-0" },
              [
                _c("p", [_c("strong", [_vm._v("Update Username:")])]),
                _vm._v(" "),
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm8: "" } },
                      [
                        _c("v-text-field", {
                          attrs: { box: "", label: "Username" },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.updateUsername.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.username,
                            callback: function ($$v) {
                              _vm.username = $$v
                            },
                            expression: "username",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-flex", { attrs: { sm1: "" } }),
                    _vm._v(" "),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm3: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              block: "",
                              disabled:
                                this.updating ||
                                this.username === this.user.username,
                            },
                            on: { click: _vm.updateUsername },
                          },
                          [
                            _vm._v(
                              "\n                            Update\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "hidden-sm-and-up" }, [
                  _c("br"),
                  _c("br"),
                ]),
                _vm._v(" "),
                _c("p", [_c("strong", [_vm._v("Update Email Address:")])]),
                _vm._v(" "),
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm8: "" } },
                      [
                        _c("v-text-field", {
                          attrs: { box: "", label: "Email Address" },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.updateEmail.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-flex", { attrs: { sm1: "" } }),
                    _vm._v(" "),
                    _c("v-flex", { attrs: { xs12: "", sm3: "" } }, [
                      _c("div", { staticClass: "email-button-container" }, [
                        _c(
                          "div",
                          { staticClass: "email-button-update" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  block: "",
                                  disabled:
                                    this.updating ||
                                    this.email === this.user.email,
                                },
                                on: { click: _vm.updateEmail },
                              },
                              [
                                _vm._v(
                                  "\n                                    Update\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "email-button-verify" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  outline: "",
                                  block: "",
                                  disabled: this.updating || _vm.user.verified,
                                },
                                on: { click: _vm.verifyEmail },
                              },
                              [
                                _vm.user.verified
                                  ? [
                                      _c("v-icon", [_vm._v("verified_user")]),
                                      _vm._v(
                                        " Verified\n                                    "
                                      ),
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                                        Verify\n                                    "
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "hidden-sm-and-up" }, [
                  _c("br"),
                  _c("br"),
                ]),
                _vm._v(" "),
                _c("p", [_c("strong", [_vm._v("Update Password:")])]),
                _vm._v(" "),
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm8: "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            type: "password",
                            box: "",
                            label: "Current Password",
                          },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.updatePassword.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.password.current,
                            callback: function ($$v) {
                              _vm.$set(_vm.password, "current", $$v)
                            },
                            expression: "password.current",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-flex", { attrs: { sm1: "" } }),
                    _vm._v(" "),
                    _c("v-flex", { attrs: { xs12: "", sm3: "" } }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm8: "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            type: "password",
                            box: "",
                            label: "New Password",
                          },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.updatePassword.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.password.new,
                            callback: function ($$v) {
                              _vm.$set(_vm.password, "new", $$v)
                            },
                            expression: "password.new",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-flex", { attrs: { sm1: "" } }),
                    _vm._v(" "),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm3: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              block: "",
                              disabled:
                                this.updating ||
                                !this.password.current ||
                                !this.password.new,
                            },
                            on: { click: _vm.updatePassword },
                          },
                          [
                            _vm._v(
                              "\n                            Update\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "hidden-sm-and-up" }, [
                  _c("br"),
                  _c("br"),
                ]),
                _vm._v(" "),
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                      _c("p", { staticClass: "mt-1" }, [
                        _c("strong", [_vm._v("Forgot Password:")]),
                        _c("br"),
                        _vm._v(
                          "\n                        Get an email with a link to set a new password\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("v-flex", { attrs: { sm1: "" } }),
                    _vm._v(" "),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm3: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", block: "" },
                            on: { click: _vm.resetPassword },
                          },
                          [
                            _vm._v(
                              "\n                            Reset\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "hidden-sm-and-up" }, [
                  _c("br"),
                  _c("br"),
                ]),
                _vm._v(" "),
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                      _c("p", { staticClass: "mt-1" }, [
                        _c("strong", [_vm._v("Delete Account:")]),
                        _c("br"),
                        _vm._v(
                          "\n                        Remove your account and all saved favorites from the server.\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("v-flex", { attrs: { sm1: "" } }),
                    _vm._v(" "),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm3: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "red", dark: "", block: "" },
                            on: { click: _vm.deleteAccount },
                          },
                          [
                            _vm._v(
                              "\n                            Delete\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Log In to your "),
      _c("strong", [_vm._v("Right Track Account")]),
      _vm._v(" to update your Account settings."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }