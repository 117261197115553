var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        timeout:
          _vm.properties.duration !== undefined
            ? _vm.properties.duration
            : 4000,
        autoHeight: "",
        bottom: "",
      },
      model: {
        value: _vm.properties.visible,
        callback: function ($$v) {
          _vm.$set(_vm.properties, "visible", $$v)
        },
        expression: "properties.visible",
      },
    },
    [
      _vm._v("\n    \n    " + _vm._s(_vm.properties.message) + "\n    \n    "),
      _c(
        "v-btn",
        { attrs: { color: "red", flat: "" }, on: { click: _vm.dismiss } },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.properties.dismiss ? _vm.properties.dismiss : "Dismiss"
              ) +
              "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }