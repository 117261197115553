var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400", persistent: "" },
      model: {
        value: _vm.properties.active,
        callback: function ($$v) {
          _vm.$set(_vm.properties, "active", $$v)
        },
        expression: "properties.active",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.properties.title) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "progress" },
            [
              _vm.properties.type === "spinner"
                ? _c("v-progress-circular", {
                    attrs: { size: 60, color: "primary", indeterminate: "" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.properties.type === "linear"
                ? _c("v-progress-linear", {
                    attrs: {
                      indeterminate: !_vm.properties.progress,
                      value: _vm.properties.progress,
                      color: "primary",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.properties.loaded || _vm.properties.total
            ? _c("v-card-text", { staticClass: "sizes" }, [
                _vm.properties.loaded
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.properties.loaded) + " MB / "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.properties.total
                  ? _c("span", [_vm._v(_vm._s(_vm.properties.total) + " MB")])
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }