var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-nav",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "secondary-bg",
      attrs: { value: _vm.display, active: _vm.page, fixed: "" },
      on: {
        "update:value": function ($event) {
          _vm.display = $event
        },
        "update:active": function ($event) {
          _vm.page = $event
        },
      },
    },
    [
      _c(
        "v-btn",
        {
          attrs: { value: "favorites", flat: "" },
          on: {
            click: function ($event) {
              return _vm.onBottomBarItemSelected("favorites")
            },
          },
        },
        [
          _c("span", [_vm._v("Favorites")]),
          _vm._v(" "),
          _c("v-icon", [_vm._v("star")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { value: "trips", flat: "" },
          on: {
            click: function ($event) {
              return _vm.onBottomBarItemSelected("trips")
            },
          },
        },
        [
          _c("span", [_vm._v("Trips")]),
          _vm._v(" "),
          _c("v-icon", [_vm._v("train")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { value: "stations", flat: "" },
          on: {
            click: function ($event) {
              return _vm.onBottomBarItemSelected("stations")
            },
          },
        },
        [
          _c("span", [_vm._v("Stations")]),
          _vm._v(" "),
          _c("v-icon", [_vm._v("access_time")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { value: "alerts", flat: "" },
          on: {
            click: function ($event) {
              return _vm.onBottomBarItemSelected("alerts")
            },
          },
        },
        [
          _c(
            "span",
            [
              _vm._v("Alerts "),
              _vm.transitAlertCount && _vm.transitAlertCount > 0
                ? [_vm._v(" (" + _vm._s(_vm.transitAlertCount) + ")")]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.transitAlertCount && _vm.transitAlertCount > 0
            ? _c("v-icon", [_vm._v("warning")])
            : _c("v-icon", [_vm._v("check_circle")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "hidden-xs-only",
          attrs: { value: "map", flat: "" },
          on: {
            click: function ($event) {
              return _vm.onBottomBarItemSelected("map")
            },
          },
        },
        [
          _c("span", [_vm._v("Map")]),
          _vm._v(" "),
          _c("v-icon", [_vm._v("map")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }