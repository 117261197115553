var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-slide-y-transition",
    [
      _vm.visible
        ? _c(
            "v-alert",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onResize,
                  expression: "onResize",
                },
              ],
              staticClass: "offline-alert",
              attrs: {
                id: "offline-alert",
                icon: "cloud_off",
                dismissible: true,
                value: "true",
              },
            },
            [
              _c("span", { staticClass: "message" }, [
                _c("strong", [_vm._v("Offline Mode")]),
                _vm._v(
                  "  For the most recent status information, make sure you have an active internet connection.\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }