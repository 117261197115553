var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700", persistent: "", scrollable: "" },
      model: {
        value: _vm.properties.visible,
        callback: function ($$v) {
          _vm.$set(_vm.properties, "visible", $$v)
        },
        expression: "properties.visible",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "card" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline secondary-bg" },
            [
              _c("v-icon", [_vm._v("list")]),
              _vm._v(
                "  Train " +
                  _vm._s(_vm.properties.trainNumber) +
                  "\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-icon", { on: { click: _vm.close } }, [_vm._v("close")]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.properties.trip
            ? _c("rt-trip-details", {
                staticClass: "card-body",
                attrs: {
                  trip: _vm.properties.trip,
                  enter: _vm.enter,
                  exit: _vm.exit,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }