var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-slide-y-reverse-transition",
    [
      _vm.properties.visible
        ? _c(
            "v-toolbar",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onResize,
                  expression: "onResize",
                },
              ],
              staticClass: "toolbar secondary-bg",
              style: {
                "padding-left":
                  _vm.drawerVisible && !_vm.isSmallScreen ? "250px" : "0",
              },
              attrs: { dense: "" },
            },
            [
              _c(
                "div",
                { staticClass: "toolbar-container" },
                [
                  _c(
                    "v-slide-y-reverse-transition",
                    { attrs: { mode: "out-in" } },
                    [
                      _c(
                        "v-toolbar-title",
                        {
                          key: _vm.title.text,
                          on: {
                            click: function ($event) {
                              return _vm.selectMessage(_vm.link)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "title",
                              style: {
                                "background-color": _vm.title.backgroundColor
                                  ? _vm.title.backgroundColor
                                  : "transparent",
                                color: _vm.title.textColor
                                  ? _vm.title.textColor + " !important"
                                  : "inherit",
                                cursor: _vm.link ? "pointer" : "auto",
                              },
                            },
                            [
                              _vm.title.icon
                                ? _c(
                                    "v-icon",
                                    {
                                      staticStyle: {
                                        "font-size": "18px !important",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.title.icon))]
                                  )
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.title.text) +
                                  "\n                    "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "subtitle",
                              style: {
                                "background-color": _vm.subtitle.backgroundColor
                                  ? _vm.subtitle.backgroundColor
                                  : "transparent",
                                color: _vm.subtitle.textColor
                                  ? _vm.subtitle.textColor + " !important"
                                  : "inherit",
                                cursor: _vm.link ? "pointer" : "auto",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.subtitle.text) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.badge
                            ? _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  style: {
                                    cursor: _vm.link ? "pointer" : "auto",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.badge) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }