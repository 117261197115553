var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "container" }, [
    _vm.display === "request"
      ? _c(
          "div",
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "secondary-bg" },
                  [
                    _c("v-icon", { attrs: { large: "", left: "" } }, [
                      _vm._v("lock_open"),
                    ]),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Reset Password")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  [
                    _c("p", { staticClass: "subheading" }, [
                      _vm._v(
                        "\n                    If you've forgotten the password to your existing "
                      ),
                      _c("strong", [_vm._v("Right Track Account")]),
                      _vm._v(
                        ", \n                    you can request a password reset link which will allow you to choose a new password.  \n                    This link will be sent to the email address registered with your account.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "v-form",
                      {
                        ref: "form_user",
                        model: {
                          value: _vm.valid_user,
                          callback: function ($$v) {
                            _vm.valid_user = $$v
                          },
                          expression: "valid_user",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            box: "",
                            "prepend-icon": "person",
                            label: "Username or Email",
                            type: "email",
                            rules: [_vm.rules.required],
                          },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.request.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.user,
                            callback: function ($$v) {
                              _vm.user = $$v
                            },
                            expression: "user",
                          },
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "button-request-container" }, [
                          _c(
                            "div",
                            { staticClass: "button-request" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: !_vm.valid_user || _vm.requesting,
                                    color: "primary",
                                  },
                                  on: { click: _vm.request },
                                },
                                [
                                  _c("v-icon", [_vm._v("lock_open")]),
                                  _vm._v(
                                    "  Reset Password\n                            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "button-request-login" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.requesting,
                                    color: "primary",
                                    outline: "",
                                  },
                                  on: {
                                    click: _vm.cancel,
                                    "update:disabled": function ($event) {
                                      _vm.requesting = $event
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("cancel")]),
                                  _vm._v(
                                    "  Cancel\n                            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display === "sent"
      ? _c(
          "div",
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "secondary-bg" },
                  [
                    _c("v-icon", { attrs: { large: "", left: "" } }, [
                      _vm._v("lock_open"),
                    ]),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Link Sent")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-card-text", [
                  _c("p", { staticClass: "subheading" }, [
                    _vm._v(
                      "\n                    A link to change your password has been sent to the email address registered to the account.\n                    Look for an email from "
                    ),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.request_resp.confirmation.from)),
                    ]),
                    _vm._v(" with a subject of\n                    "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.request_resp.confirmation.subject)),
                    ]),
                    _vm._v(
                      ".  You may need to check your mail's \n                    Junk/Spam folder.  The link will expire on " +
                        _vm._s(_vm.request_resp.token.expires) +
                        ".\n                "
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display === "update"
      ? _c(
          "div",
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "secondary-bg" },
                  [
                    _c("v-icon", { attrs: { large: "", left: "" } }, [
                      _vm._v("lock_open"),
                    ]),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Reset Password")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  [
                    _c("p", { staticClass: "subheading" }, [
                      _vm._v(
                        "\n                    Enter your new password in the form below.  Once your password is reset, you will need \n                    to login to any existing browser sessions.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "v-form",
                      {
                        ref: "form_pass",
                        model: {
                          value: _vm.valid_pass,
                          callback: function ($$v) {
                            _vm.valid_pass = $$v
                          },
                          expression: "valid_pass",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            box: "",
                            "prepend-icon": "lock",
                            label: "Password",
                            type: "password",
                            rules: [_vm.rules.required],
                          },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.update.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.pass,
                            callback: function ($$v) {
                              _vm.pass = $$v
                            },
                            expression: "pass",
                          },
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "button-update-container" }, [
                          _c(
                            "div",
                            { staticClass: "button-update" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: !_vm.valid_pass || _vm.updating,
                                    color: "primary",
                                  },
                                  on: { click: _vm.update },
                                },
                                [
                                  _c("v-icon", [_vm._v("lock_open")]),
                                  _vm._v(
                                    "  Reset Password\n                            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "button-update-login" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.updating,
                                    color: "primary",
                                    outline: "",
                                  },
                                  on: {
                                    click: _vm.cancel,
                                    "update:disabled": function ($event) {
                                      _vm.updating = $event
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("cancel")]),
                                  _vm._v(
                                    "  Cancel\n                            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display === "success"
      ? _c(
          "div",
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "secondary-bg" },
                  [
                    _c("v-icon", { attrs: { large: "", left: "" } }, [
                      _vm._v("lock_open"),
                    ]),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Reset Password")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("v-icon", { attrs: { large: "" } }, [_vm._v("lock")]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { staticClass: "subheading" }, [
                      _c("strong", [_vm._v("Password Reset!")]),
                      _vm._v(" "),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n                    You must log in again with your new password.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.updating,
                          color: "primary",
                          block: "",
                        },
                        on: {
                          click: _vm.login,
                          "update:disabled": function ($event) {
                            _vm.updating = $event
                          },
                        },
                      },
                      [
                        _c("v-icon", [_vm._v("person_outline")]),
                        _vm._v("  Log In\n                "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }