var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "page" },
    [
      _c(
        "v-card",
        [
          _c(
            "div",
            { attrs: { id: "header-card" } },
            [
              _c(
                "v-card-title",
                { staticClass: "secondary-bg" },
                [
                  _c(
                    "span",
                    { staticClass: "headline card-title" },
                    [
                      _c(
                        "v-fade-transition",
                        { attrs: { "hide-on-leave": "" } },
                        [
                          _vm.icon
                            ? _c("v-icon", { key: _vm.icon }, [
                                _vm._v(_vm._s(_vm.icon)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v("\n                      \n                    "),
                      _c(
                        "v-fade-transition",
                        { attrs: { "hide-on-leave": "" } },
                        [
                          _c("span", { key: _vm.title }, [
                            _vm._v(_vm._s(_vm.title)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.transitFeedUpdated
                    ? _c(
                        "span",
                        { staticClass: "subheading v-small-hide" },
                        [
                          _c(
                            "v-icon",
                            { staticStyle: { "font-size": "20px" } },
                            [_vm._v("update")]
                          ),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.transitFeedUpdated) +
                              "\n                "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.nav && _vm.nav.length > 0
                ? _c(
                    "div",
                    { staticClass: "nav" },
                    _vm._l(_vm.nav, function (item, index) {
                      return _c(
                        "div",
                        { key: "nav-item-" + index, staticClass: "nav-item" },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.navigate(item.path)
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "nav-icon" }, [
                                _vm._v("chevron_left"),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-label" }, [
                                _vm._v(_vm._s(item.label)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in", duration: 0 } },
            [
              !_vm.transitAgencyId
                ? _c("rt-transit-agency-list", {
                    attrs: {
                      id: "rt-transit-agency-list",
                      transitAgencies: _vm.transitAgencies,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in", duration: 0 } },
            [
              _vm.transitFeed || _vm.transitDivision
                ? _c("rt-transit-division-list", {
                    attrs: {
                      id: "rt-transit-division-list",
                      feed: _vm.transitFeed,
                      transitDivision: _vm.transitDivision,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "slide-fade", duration: 0 } },
        [
          _vm.transitDivision
            ? _c("rt-transit-event-list", {
                attrs: {
                  id: "rt-transit-events",
                  transitDivision: _vm.transitDivision,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }